import React, {useState, useEffect, useContext} from "react";
import FormRow from "./FormRow";
import {AuthContext} from "../contexts/AuthContext";
import Error from "./Error";
import {getForms} from "../models/forms";
import Spinner from "./Spinner";

function FormHistory() {
    const [forms, setForms] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    const {token} = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const response = await getForms(token);
                console.log(response);
                if (response.errors.length > 0) {
                    setErrors(response.errors);
                }
                else {
                    setForms(response.forms);
                }
            }
            catch (e) {
                console.error(e);
                setErrors(["Failed to reach back end."]);
            }
            setLoading(false);
        })();
    }, []);

    return (
        <div className={"mx-2 mt-3 p-5 border bg-white shadow"}>
            {errors.map((error, index) => <Error key={index} message={error} />)}

            {loading ? <Spinner /> : (
                <table className={"table table-striped"}>
                    <caption>Form History</caption>
                    <thead className={"bg-dark text-white"}>
                    <tr>
                        <th>Project name</th>
                        <th>Creation stamp</th>
                        <th>Updating stamp</th>
                    </tr>
                    </thead>
                    <tbody>
                    {forms.map((value, index) => {
                        return <FormRow key={index} form={value} />;
                    })}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default FormHistory;