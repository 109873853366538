import React from "react";
import {NavLink} from "react-router-dom";

function FormRow({form}) {
    return (
        <tr>
            <td><NavLink to={'/forms/' + form.id}>{form.projectName}</NavLink></td>
            <td>{form.createdAt}</td>
            <td>{form.updatedAt}</td>
        </tr>
    );
}

export default FormRow;