import React, {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";

function Logout() {
    const {setUser, setToken, user} = useContext(AuthContext);

    function logout() {
        localStorage.setItem("token", "");
        setUser({});
        setToken(null);
    }
    return (
        <div>
            <span className={"text-white"}>{user.firstName} {user.lastName}</span>
            <button type={"button"} onClick={logout} className={"btn btn-danger ms-2"}>Logout</button>
        </div>
    );
}

export default Logout;