import React, {useState, useEffect, Fragment} from "react";
import Navbar from "./Navbar";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import DesignGuide from "./DesignGuide";
import FormHistory from "./FormHistory";

function DesignGuideApp() {
    const [data, setData] = useState({});
    const [forms, setForms] = useState([]);

    useEffect(() => {
        //TODO get all forms
    }, []);

    async function save() {

        //TODO send data to server and clear, add from to array
    }

    async function getForm(formId) {

        //TODO get data and set it
    }

    return (
        <Fragment>
            <Router>
                <Navbar />

                <Switch>
                    <Route path={'/designGuide'} exact={true}>
                        <DesignGuide />
                    </Route>
                    <Route path={'/forms'} exact={true}>
                        <FormHistory />
                    </Route>
                    <Route path={'/forms/:formId'} exact={true}>
                        <DesignGuide />
                    </Route>

                    <Route path={'*'}>
                        <Redirect to={'/designGuide'} />
                    </Route>
                </Switch>
            </Router>
        </Fragment>
    );
}

export default DesignGuideApp;