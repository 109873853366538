import React, {useState, useEffect} from "react";
import {AuthContext} from "./contexts/AuthContext";
import Login from "./components/Login";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import {getUser} from "./models/auth";
import DesignGuideApp from "./components/DesignGuideApp";
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/ForgotPassword";


function App() {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(null);

    useEffect(() => {
        (async () => {
            const tokenFromLocalStorage = localStorage.getItem('token');
            if (tokenFromLocalStorage) {
                try {
                    const response = await getUser(tokenFromLocalStorage, setToken, setUser);
                    if (response.errors.length <= 0) {
                        setUser(response.user);
                        setToken(tokenFromLocalStorage);
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        })();
    }, []);

    return (
        <AuthContext.Provider value={{
            user,
            token,
            setUser,
            setToken
        }}>
            <div className={"container-fluid bg-light p-0 m-0"} style={{height: '100vh'}}>
                {token ? <DesignGuideApp /> : (
                    <Router>
                        <Switch>
                            <Route path={'/login'}><Login /></Route>
                            <Route path={'/resetPassword/:token'} exact={true}>
                                <ResetPassword />
                            </Route>
                            <Route path={'/forgotPassword'}>
                                <ForgotPassword />
                            </Route>
                            <Route path={'*'}>
                                <Redirect to={'/login'} />
                            </Route>
                        </Switch>
                    </Router>
                )}
            </div>
        </AuthContext.Provider>
    );
}

export default App;
