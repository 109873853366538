import React, {useState, useEffect, useContext, Fragment} from "react";
import {createForm, getOne, updateForm} from "../models/forms";
import {AuthContext} from "../contexts/AuthContext";
import Error from "./Error";
import Spinner from "./Spinner";
import {useHistory, useParams} from "react-router-dom";
import {NavLink} from "react-router-dom";

function DesignGuide() {
    const [q1, setQ1] = useState("");
    const [q2, setQ2] = useState("");
    const [q3, setQ3] = useState("");
    const [q4, setQ4] = useState("");
    const [q6, setQ6] = useState("");
    const [q7, setQ7] = useState("");
    const [q8, setQ8] = useState("");
    const [q10, setQ10] = useState("");
    const [q12, setQ12] = useState("");
    const [q13, setQ13] = useState("");
    const [q24, setQ24] = useState("");
    const [q25, setQ25] = useState("");
    const [q26, setQ26] = useState("");
    const [q32, setQ32] = useState("");
    const [q34, setQ34] = useState("");
    const [q36, setQ36] = useState("");
    const [q39, setQ39] = useState("");
    const [q40, setQ40] = useState("");
    const [q42, setQ42] = useState("");
    const [q45, setQ45] = useState("");
    const [q46, setQ46] = useState("");
    const [q50, setQ50] = useState("");
    const [q51, setQ51] = useState("");
    const [q55, setQ55] = useState("");
    const [q80, setQ80] = useState("");
    const [q56, setQ56] = useState("");
    const [q57, setQ57] = useState("");
    const [q66, setQ66] = useState("");
    const [q70, setQ70] = useState("");

    const [loading, setLoading] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [errors, setErrors] = useState([]);

    const [q1D, setQ1D] = useState(false);
    const [q2D, setQ2D] = useState(true);
    const [q3D, setQ3D] = useState(true);
    const [q4D, setQ4D] = useState(true);
    const [q5D, setQ5D] = useState(true);
    const [q6D, setQ6D] = useState(true);
    const [q7D, setQ7D] = useState(true);
    const [q8D, setQ8D] = useState(true);
    const [q9D, setQ9D] = useState(true);
    const [q10D, setQ10D] = useState(true);
    const [q11D, setQ11D] = useState(true);
    const [q12D, setQ12D] = useState(true);
    const [q13D, setQ13D] = useState(true);
    const [q14D, setQ14D] = useState(true);
    const [q15D, setQ15D] = useState(true);
    const [q16D, setQ16D] = useState(true);
    const [q17D, setQ17D] = useState(true);
    const [q18D, setQ18D] = useState(true);
    const [q19D, setQ19D] = useState(true);
    const [q20D, setQ20D] = useState(true);
    const [q21D, setQ21D] = useState(true);
    const [q22D, setQ22D] = useState(true);
    const [q23D, setQ23D] = useState(true);
    const [q24D, setQ24D] = useState(true);
    const [q25D, setQ25D] = useState(true);
    const [q26D, setQ26D] = useState(true);
    const [q27D, setQ27D] = useState(true);
    const [q28D, setQ28D] = useState(true);
    const [q29D, setQ29D] = useState(true);
    const [q30D, setQ30D] = useState(true);

    const [q31D, setQ31D] = useState(true);
    const [q32D, setQ32D] = useState(true);
    const [q33D, setQ33D] = useState(true);
    const [q34D, setQ34D] = useState(true);
    const [q35D, setQ35D] = useState(true);
    const [q36D, setQ36D] = useState(true);
    const [q37D, setQ37D] = useState(true);
    const [q38D, setQ38D] = useState(true);
    const [q39D, setQ39D] = useState(true);
    const [q40D, setQ40D] = useState(true);

    const [q41D, setQ41D] = useState(true);
    const [q42D, setQ42D] = useState(true);
    const [q43D, setQ43D] = useState(true);
    const [q44D, setQ44D] = useState(true);
    const [q45D, setQ45D] = useState(true);
    const [q46D, setQ46D] = useState(true);
    const [q47D, setQ47D] = useState(true);
    const [q48D, setQ48D] = useState(true);
    const [q49D, setQ49D] = useState(true);
    const [q50D, setQ50D] = useState(true);

    const [q51D, setQ51D] = useState(true);
    const [q52D, setQ52D] = useState(true);
    const [q53D, setQ53D] = useState(true);
    const [q54D, setQ54D] = useState(true);
    const [q55D, setQ55D] = useState(true);
    const [q80D, setQ80D] = useState(true);

    const [q56D, setQ56D] = useState(true);
    const [q57D, setQ57D] = useState(true);
    const [q81D, setQ81D] = useState(true);
    const [q58D, setQ58D] = useState(true);
    const [q59D, setQ59D] = useState(true);
    const [q60D, setQ60D] = useState(true);
    
    const [q61D, setQ61D] = useState(true);
    const [q62D, setQ62D] = useState(true);
    const [q63D, setQ63D] = useState(true);
    const [q64D, setQ64D] = useState(true);
    const [q65D, setQ65D] = useState(true);
    const [q66D, setQ66D] = useState(true);
    const [q67D, setQ67D] = useState(true);
    const [q68D, setQ68D] = useState(true);
    const [q69D, setQ69D] = useState(true);
    const [q70D, setQ70D] = useState(true);

    const [q71D, setQ71D] = useState(true);
    const [q72D, setQ72D] = useState(true);
    const [q73D, setQ73D] = useState(true);
    const [q74D, setQ74D] = useState(true);
    const [q75D, setQ75D] = useState(true);
    const [q76D, setQ76D] = useState(true);
    const [q77D, setQ77D] = useState(true);
    const [q78D, setQ78D] = useState(true);
    const [q79D, setQ79D] = useState(true);

    const [RdD, setRdD] = useState(true);


    const {token} = useContext(AuthContext);
    const {formId} = useParams();
    const history = useHistory();

    useEffect(() => {
        if (formId) {
            (async () => {
                setLoading(true);
                try {
                    const response = await getOne(formId, token);
                    if (response.errors.length > 0) {
                        setErrors(response.errors);
                    }
                    else {
                        setProjectName(response.form.projectName);
                        console.log(response.form.data);
                        console.log(JSON.parse(response.form.data));

                        const data = JSON.parse(response.form.data);
                        console.log(data.q1, typeof data);
                        setQ1(data.q1);
                        setQ2(data.q2);
                        setQ3(data.q3);
                        setQ4(data.q4);
                        setQ6(data.q6);
                        setQ7(data.q7);
                        setQ8(data.q8);
                        setQ10(data.q10);
                        setQ12(data.q12);
                        setQ13(data.q13);
                        setQ24(data.q24);
                        setQ25(data.q25);
                        setQ26(data.q26);
                        setQ32(data.q32);
                        setQ34(data.q34);
                        setQ36(data.q36);
                        setQ39(data.q39);
                        setQ40(data.q40);
                        setQ42(data.q42);
                        setQ45(data.q45);
                        setQ46(data.q46);
                        setQ50(data.q50);
                        setQ51(data.q51);
                        setQ55(data.q55);
                        setQ80(data.q80);
                        setQ56(data.q56);
                        setQ57(data.q57);
                        setQ66(data.q66);
                        setQ70(data.q70);
                    }
                }
                catch (e) {
                    console.error(e);
                    setErrors(["Failed to reach back end."]);
                }

                setLoading(false);
            })();
        }
        else {
            setProjectName("");
            setQ1("");
            setQ2("");
            setQ3("");
            setQ4("");
            setQ6("");
            setQ7("");
            setQ8("");
            setQ10("");
            setQ12("");
            setQ13("");
            setQ24("");
            setQ25("");
            setQ26("");
            setQ32("");
            setQ34("");
            setQ36("");
            setQ39("");
            setQ40("");
            setQ42("");
            setQ45("");
            setQ46("");
            setQ50("");
            setQ51("");
            setQ55("");
            setQ80("");
            setQ56("");
            setQ57("");
            setQ66("");
            setQ70("");
        }
    }, [formId]);

    useEffect(() => {
        if (!loading) {
            setQ2("");
            setQ3("");
        }

        setQ3D(q1 !== "yes");
        setQ2D(q1 !== "no");
    }, [q1]);

    useEffect(() => {
        if (!loading) {
            setQ3("");
        }

        setRdD(q2 !== "yes");

        if (q2 === "no") {
            setQ3D(false);
        }
        else if (q1 !== "yes"){
            setQ3D(true);
        }

    }, [q2]);

    useEffect(() => {
        if (!loading) {
            setQ4("");
        }

        setQ5D(q3 !== "yes");
        setQ4D(q3 !== "no");
    }, [q3]);

    useEffect(() => {
        setQ5D(q4 !== "yes");
        setRdD(q4 !== "no");
    }, [q4]);

    useEffect(() => {
        if (!loading) {
            setQ6("");
        }
        setQ6D(q5D);
    }, [q5D]);

    useEffect(() => {
        if (!loading) {
            setQ7("");
            setQ10("");
        }

        setQ10D(!(!q9D || q6 === "no"))
        // setQ10D(q6 !== "no");
        setQ7D(q6 !== "yes");
    }, [q6]);

    useEffect(() => {
        if (!loading) {
            setQ8("");
        }

        setQ30D(!(q8 === "no" || q7 === "no"));
        setQ8D(q7 !== "yes");
    }, [q7]);

    useEffect(() => {
        setQ30D(!(q8 === "no" || q7 === "no"));
        setQ9D(q8 !== "yes");
    }, [q8]);

    useEffect(() => {
        if (!loading) {
            setQ10("");
        }
        setQ10D(!(!q9D || q6 === "no"));
    }, [q9D]);

    useEffect(() => {
        if (!loading) {
            setQ13("");
        }
        setQ13D(q10 !== "no");
        setQ11D(q10 !== "yes");
    }, [q10]);

    useEffect(() => {
        if (!loading) {
            setQ12("");
        }
        setQ12D(q11D);
    }, [q11D]);

    useEffect(() => {
        setQ16D(q12 !== "no");
        setQ18D(q12 !== "yes");
    }, [q12]);

    useEffect(() => {
        setQ15D(q13 !== "no");
        setQ14D(q13 !== "yes");
    }, [q13]);

    useEffect(() => {
        setQ20D(!(!q19D || !q14D));
    }, [q14D]);

    useEffect(() => {
        setQ17D(!(!q15D || !q16D));
    }, [q15D]);

    useEffect(() => {
        setQ17D(!(!q16D || !q16D));
    }, [q16D]);

    useEffect(() => {
        setQ19D(!(!q17D || !q18D));
    }, [q17D]);

    useEffect(() => {
        setQ19D(!(!q17D || !q18D));
    }, [q18D]);

    useEffect(() => {
        setQ20D(!(!q19D || !q14D));
    }, [q19D]);

    useEffect(() => {
        setQ21D(q20D);
    }, [q20D]);

    useEffect(() => {
        setQ22D(q21D);
    }, [q21D]);

    useEffect(() => {
        setQ23D(q22D);
    }, [q22D]);

    useEffect(() => {
        if (!loading) {
            setQ24("");
        }
        setQ24D(q23D);
    }, [q23D]);

    useEffect(() => {
        if (!loading) {
            setQ25("");
            setQ70("");
        }
        setQ70D(!(q24 === "no" || q25 === "no" || !q69D));
        setQ25D(q24 !== "yes");
    }, [q24]);

    useEffect(() => {
        if (!loading) {
            setQ26("");
            setQ70("");
        }
        setQ70D(!(q24 === "no" || q25 === "no" || !q69D));
        setQ26D(q25 !== "yes");
    }, [q25]);

    useEffect(() => {
        setQ27D(q26 !== "no");
        setQ28D(q26 !== "yes");
    }, [q26]);

    useEffect(() => {
        setQ29D(!(!q27D || !q28D));
    }, [q27D]);

    useEffect(() => {
        setQ29D(!(!q27D || !q28D));
    }, [q28D]);

    useEffect(() => {
        setQ65D(q29D);
    }, [q29D]);

    useEffect(() => {
        setQ31D(q30D);
    }, [q30D]);

    useEffect(() => {
        if (!loading) {
            setQ32("");
        }
        setQ32D(q31D);
    }, [q31D]);

    useEffect(() => {
        if (!loading) {
            setQ39("");
        }
        setQ33D(q32 !== "no");
        setQ39D(q32 !== "yes");
    }, [q32]);

    useEffect(() => {
        if (!loading) {
            setQ34("");
        }
        setQ34D(q33D);
    }, [q33D]);

    useEffect(() => {
        if (!loading) {
            setQ36("");
        }
        setQ36D(!(q34 === "no" || !q35D));
        setQ35D(q34 !== "yes");
    }, [q34]);

    useEffect(() => {
        if (!loading) {
            setQ36("");
        }
        setQ36D(!(q34 === "no" || !q35D));
    }, [q35D]);

    useEffect(() => {
        setQ38D(!(q36 === "no" || !q37D));
        setQ37D(q36 !== "yes");
    }, [q36]);

    useEffect(() => {
        setQ38D(!(q36 === "no" || !q37D));
    }, [q37D]);

    useEffect(() => {
        if (!loading) {
            setQ50("");
        }
        setQ50D(q38D);
    }, [q38D]);

    useEffect(() => {
        if (!loading) {
            setQ40("");
        }
        setQ41D(q39 !== "no");
        setQ40D(q39 !== "yes");
    }, [q39]);

    useEffect(() => {
        if (!loading) {
            setQ42("");
            setQ45("");
        }
        setQ42D(q40 !== "no");
        setQ45D(q40 !== "yes");
    }, [q40]);

    useEffect(() => {
        setQ52D(q41D);
    }, [q41D]);

    useEffect(() => {
        setQ43D(q42 !== "no");
        setQ44D(q42 !== "yes");
    }, [q42]);

    useEffect(() => {
        setQ53D(!(!q43D || !q44D || q51 === "no"));
    }, [q43D]);

    useEffect(() => {
        setQ53D(!(!q43D || !q44D || q51 === "no"));
    }, [q44D]);

    useEffect(() => {
        if (!loading) {
            setQ46("");
        }
        setQ46D(q45 !== "no");
        setQ47D(q45 !== "yes");
    }, [q45]);

    useEffect(() => {
        setQ49D(q46 !== "no");
        setQ48D(q46 !== "yes");
    }, [q46]);

    useEffect(() => {
        setQ54D(!(!q47D || !q48D || !q49D || q51 === "yes"));
    }, [q47D]);

    useEffect(() => {
        setQ54D(!(!q47D || !q48D || !q49D || q51 === "yes"));
    }, [q48D]);

    useEffect(() => {
        setQ54D(!(!q47D || !q48D || !q49D || q51 === "yes"));
    }, [q49D]);

    useEffect(() => {
        if (!loading) {
            setQ51("");
        }
        setQ52D(q50 !== "no");
        setQ51D(q50 !== "yes");
    }, [q50]);

    useEffect(() => {
        setQ53D(!(!q43D || !q44D || q51 === "no"));
        setQ54D(!(!q47D || !q48D || !q49D || q51 === "yes"));
    }, [q51]);

    useEffect(() => {
        if (!loading) {
            setQ55("");
        }
        setQ55D(q52D);
    }, [q52D]);

    useEffect(() => {
        if (!loading) {
            setQ56("");
        }
        setQ56D(q53D);
    }, [q53D]);

    useEffect(() => {
        if (!loading) {
            setQ57("");
        }
        setQ57D(!(!q54D || q55 === "yes" || q56 === "yes"));
    }, [q54D]);

    useEffect(() => {
        if (!loading) {
            setQ57("");
            setQ80("");
        }
        setQ80D(!(q55 === "no"));
        setQ57D(!(!q54D || q55 === "yes" || q56 === "yes"));
    }, [q55]);

    useEffect(() => {
        setQ81D(!(q80 === "no"));
        setQ59D(!(q57 === "yes" || !q58D || q80 === "yes" || q56 === "no"));
    }, [q80]);

    useEffect(() => {
        console.log("Q56: " + q56);
        if (!loading) {
            setQ57("");
        }
        setQ59D(!(q57 === "yes" || !q58D || q80 === "yes" || q56 === "no"));
        setQ57D(!(!q54D || q55 === "yes" || q56 === "yes"));
    }, [q56]);

    useEffect(() => {
        /*if (!loading) {
            // setQ57("");
        }*/
        console.log("Q57: " + q57);

        setQ58D(!(q57 === "no"));
        setQ59D(!(q57 === "yes" || !q58D || q80 === "yes" || q56 === "no"));
    }, [q57]);

    useEffect(() => {
        setQ60D(!(!q81D || !q59D));
    }, [q81D]);

    useEffect(() => {
        setQ59D(!(q57 === "yes" || !q58D || q80 === "yes" || q56 === "no"));
    }, [q58D]);

    useEffect(() => {
        setQ60D(!(!q81D || !q59D));
    }, [q59D]);

    useEffect(() => {
        setQ61D(q60D);
    }, [q60D]);

    useEffect(() => {
        setQ62D(q61D);
    }, [q61D]);

    useEffect(() => {
        setQ63D(q62D);
    }, [q62D]);

    useEffect(() => {
        setQ64D(q63D);
    }, [q63D]);

    useEffect(() => {
        setQ72D(q64D);
    }, [q64D]);

    useEffect(() => {
        if (!loading) {
            setQ66("");
        }
        setQ66D(q65D);
    }, [q65D]);

    useEffect(() => {
        setQ67D(q66 !== "no");
        setQ68D(q66 !== "yes");
    }, [q66]);

    useEffect(() => {
        setQ69D(!(!q67D || !q68D));
    }, [q67D]);

    useEffect(() => {
        setQ69D(!(!q67D || !q68D));
    }, [q68D]);

    useEffect(() => {
        if (!loading) {
            setQ70("");
        }
        setQ70D(!(q24 === "no" || q25 === "no" || !q69D));
    }, [q69D]);

    useEffect(() => {
        setQ75D(!(q70 === "no" || !q71D || !q74D));
        setQ71D(q70 !== "yes");
    }, [q70]);

    useEffect(() => {
        setQ75D(!(q70 === "no" || !q71D || !q74D));
    }, [q71D]);

    useEffect(() => {
        setQ73D(q72D);
    }, [q72D]);

    useEffect(() => {
        setQ74D(q73D);
    }, [q73D]);

    useEffect(() => {
        setQ75D(!(q70 === "no" || !q71D || !q74D));
    }, [q74D]);

    useEffect(() => {
        setQ76D(q75D);
    }, [q75D]);

    useEffect(() => {
        setQ77D(q76D);
    }, [q76D]);

    useEffect(() => {
        setQ78D(q77D);
    }, [q77D]);

    useEffect(() => {
        setQ79D(q78D);
    }, [q78D]);

    async function submit(evt) {
        evt.preventDefault();
        setLoading(true);

        const data = JSON.stringify({
            q1,
            q2,
            q3,
            q4,
            q6,
            q7,
            q8,
            q10,
            q12,
            q13,
            q24,
            q25,
            q26,
            q32,
            q34,
            q36,
            q39,
            q40,
            q42,
            q45,
            q46,
            q50,
            q51,
            q55,
            q56,
            q57,
            q66,
            q70
        });

        try {
            if (formId) {
                const response = await updateForm(formId, projectName, data, token);
                if (response.errors.length > 0) {
                    setErrors(response.errors);
                }
            }
            else {
                const response = await createForm(projectName, data, token);
                if (response.errors.length > 0) {
                    setErrors(response.errors);
                }
                else {
                    const formId = response.formId;
                    history.push('/forms/' + formId);
                }
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end."]);
        }
        setLoading(false);
    }

    return (
        <form onSubmit={submit} className={"mx-2 mt-3 p-5 border bg-white shadow"}>
            {errors.map((error, index) => <Error key={index} message={error} />)}

            {formId &&
                <Fragment>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb p-1">
                            <li className="breadcrumb-item"><NavLink to={'/forms'}>Form History</NavLink></li>
                            <li className="breadcrumb-item active" aria-current="page">{projectName}</li>
                        </ol>
                    </nav><hr/>
                </Fragment>
            }




            {loading ? <Spinner />:
                <Fragment>
                    <div className={"mb-3"}>
                        <label htmlFor="projectName" className={"form-label"}>Project name:</label>
                        <input type="text" id={"projectName"} className={"form-control"} value={projectName} onChange={(evt) => {
                            setProjectName(evt.target.value);
                        }} required={true} placeholder={"Project name"}/>
                    </div>

                    <hr/>

                    <div className={"mb-3" + (q1D ? " d-none": "")} id={"q1"}>
                        <label htmlFor="q1Q" className={"form-label"}>Does the building have more than 50 % closed façades:</label>
                        <select id="q1Q" className={"form-select"} required={!q1D} value={q1} onChange={(evt) => {
                            setQ1(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                    <div className={"mb-3" + (q2D ? " d-none": "")} id={"q2"}>
                        <label htmlFor="q2Q" className={"form-label"}>Is the maximum distance from every point in the construction to the open façade less than 30 m:</label>
                        <select id="q2Q" className={"form-select"} required={!q2D} value={q2} onChange={(evt) => {
                            setQ2(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                    <div className={"mb-3" + (q3D ? " d-none" : "")} id={"q3"}>
                        <label htmlFor="q3Q" className={"form-label"}>Does the building have a total floor area of more than 100 m²:</label>
                        <select id="q3Q" className={"form-select"}  required={!q3D} value={q3} onChange={(evt) => {
                            setQ3(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                    <div className={"mb-3" + (q4D ? " d-none": "")} id={"q4"}>
                        <label htmlFor="q4Q" className={"form-label"}>Does the building have more than 2 closed floors:</label>
                        <select id="q4Q" className={"form-select"} required={!q4D} value={q4} onChange={(evt) => {
                            setQ4(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q5D ? " d-none": "")} id={"q5"}>
                        <p>The building must have an access route and a parking area either on the public road or on a special access route from the public road.</p>
                        <p>The special access route should have the following characteristics:</p>
                        <ul>
                            <li>mimimum passable width of 4 m</li>
                            <li>minimum turning radius of 11 m inner curve and 15 m outer curve</li>
                            <li>minimum headroom of 4 m</li>
                            <li>maximum slope of 6 %</li>
                            <li>load-bearing capacity to enable vehicles with a maximum axle load of 13 t</li>
                        </ul>
                    </div>


                    <div className={"mb-3" + (q6D ? " d-none": "")} id={"q6"}>
                        <label htmlFor="q6Q" className={"form-label"}>Are there <u title={"Industrial processing or storage of materials or goods, including electric power distribution towards other buildings. Electric power distribution inside a building is not considered to be an industrial activity. Laboratory for quality control."}>industrial activities <i className="bi bi-info-circle"></i></u> inside the building:</label>
                        <select id="q6Q" className={"form-select"} required={!q6D} value={q6} onChange={(evt) => {
                            setQ6(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                    <div className={"mb-3" + (q7D ? " d-none": "")} id={"q7"}>
                        <label htmlFor="q7Q" className={"form-label"}>Is the area of the rooms with industrial activities less than 500 m²:</label>
                        <select id="q7Q" className={"form-select"} required={!q7D} value={q7} onChange={(evt) => {
                            setQ7(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                    {q7 === "no" &&     <div className={"ms-5 border my-2 bg-light p-3 shadow-sm"}>
                        <p>Industrial building</p>
                    </div>}

                    <div className={"mb-3" + (q8D ? " d-none": "")} id={"q8"}>
                        <label htmlFor="q8Q" className={"form-label"}>Is the area of the rooms with non-industrial activities higher than the area of rooms with industrial activities:</label>
                        <select id="q8Q" className={"form-select"} required={!q8D} value={q8} onChange={(evt) => {
                            setQ8(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    {q8 === "no" &&     <div className={"ms-5 border my-2 bg-light p-3 shadow-sm"}>
                        <p>Industrial building</p>
                    </div>}

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q9D ? " d-none": "")} id={"q9"}>
                        <p>In the rooms with industrial activities the distance to travel to reach the exit to a <u title={"a place outside the building or, where applicable, that part of the building located outside the compartment with the fire and from which it is possible to leave the building without having to pass through that compartment."}>safe place <i className="bi bi-info-circle"></i></u> should be no more than 30 m.</p>
                        <p>This distance can be extended to 45 m when an evacuation route or staircase, enclosed with inner walls EI 60 and inner doors EI1 30, can be reached within a distance of 30 m.</p>
                    </div>

                    <div className={"mb-3" + (q10D ? " d-none" : "")} id={"q10"}>
                        <label htmlFor="q10Q" className={"form-label"}>Is the <u title={"difference in level between the highest finished floor and the roads around the building used by the fire trucks. Technical rooms on the roof are not included."}>building height <i className="bi bi-info-circle"></i></u> higher than 10 m:</label>
                        <select id="q10Q" className={"form-select"} required={!q10D} value={q10} onChange={(evt) => {
                            setQ10(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    {q10 === "no" &&     <div className={"ms-5 border my-2 bg-light p-3 shadow-sm"}>
                        <p>Low-rise building</p>
                    </div>}

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q11D ? " d-none": "")} id={"q11"}>
                        <p>The building must be accessible to motor vehicles at all times.</p>
                    </div>

                    <div className={"mb-3" + (q12D ? " d-none": "")} id={"q12"}>
                        <label htmlFor="q12Q" className={"form-label"}>Is the building height at least 25 m:</label>
                        <select id="q12Q" className={"form-select"} required={!q12D} value={q12} onChange={(evt) => {
                            setQ12(evt.target.value)
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    {q12 === "no" &&     <div className={"ms-5 border my-2 bg-light p-3 shadow-sm"}>
                        <p>Mid-rise building</p>
                    </div>}
                    {q12 === "yes" &&     <div className={"ms-5 border my-2 bg-light p-3 shadow-sm"}>
                        <p>High-rise building</p>
                    </div>}

                    <div className={"mb-3" + (q13D ? " d-none" : "")} id={"q13"}>
                        <label htmlFor="q13Q" className={"form-label"}>Does the building have only one level:</label>
                        <select id="q13Q" className={"form-select"} required={!q13D} value={q13} onChange={(evt) => {
                            setQ13(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q14D ? " d-none": "")} id={"q14"}>
                        <p>Fire service vehicles must be able to get within 60 m of the façade of the building.</p>
                        <p>The structural elements need to have R 30.</p>
                        <p><u title={"A compartment wall is an internal wall between two compartments. In most cases vertical walls are meant, but it can also be a floor."}>Compartment walls <i className="bi bi-info-circle"></i></u> need to have EI 30.</p>
                        <p>The building should be split into fire compartments with maximum area of 3,500 m² and maximum length of 90 m.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q15D ? " d-none": "")} id={"q15"}>
                        <p>Fire service vehicles must be able to reach, in at least one location, a façade providing access to each level at recognisable locations.</p>
                        <p>The structural elements of the roof need to have R 30; the other structural elements need to have R 60.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q16D ? " d-none": "")} id={"q16"}>
                        <p>The structural elements below evacuation level and the floor at evacuation level need to have R 120; the other structural elements need to have R 60.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q17D ? " d-none" : "")} id={"q17"}>
                        <p>Compartment walls need to have EI 60.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q18D ? " d-none": "")} id={"q18"}>
                        <p>All the structural elements need to have R 120.</p>
                        <p>Compartment walls need to have EI 120.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q19D ? " d-none": "")} id={"q19"}>
                        <p>The building should be split into fire compartments with maximum area of 2,500 m².</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q20D ? " d-none": "")} id={"q20"}>
                        <p>Exceptions - bigger compartments are allowed in:</p>
                        <ul>
                            <li>car parks</li>
                            <li>compartments protected with sprinklers and smoke and heat control.</li>
                        </ul>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q21D ? " d-none": "")} id={"q21"}>
                        <p>Each building level is a separate compartment.</p>
                        <p>Exceptions:</p>
                        <ul>
                            <li>car parks</li>
                            <li>duplex compartments &lt; 2,500 m² in total</li>
                            <li>triplex compartments &lt; 300 m²</li>
                            <li>atrium compartments protected with sprinklers and smoke and heat control. A request for derogation is mandatory.</li>
                            <li>technical rooms</li>
                        </ul>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q22D ? " d-none": "")} id={"q22"}>
                        <p>In evacuation routes, rooms accessible for the public and shared kitchens, false ceilings shall be EI 30 or <u title={"Fire resistance (1/2 hour) of a false ceiling following the belgian standard NBN 713-020. "}>Rf 1/2 h<i className="bi bi-info-circle"></i></u>.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q23D ? " d-none": "")} id={"q23"}>
                        <p>Interior walls of stairwells shall have the same fire rating as compartment walls and at least EI 60.</p>
                        <p>On each story, communication between the compartment and the stairwell is provided by an EI1 30 door.</p>
                        <p>A ventilation opening discharching into the open air and having a minimum section of 1 m² shall be
                            provided in the upper part of each internal stairwell. This opening is normally closed; its opening
                            device is controlled manually and it is located visibly on the evacuation story.</p>
                        <p>Exception for stairwells between evacuation level and underground storeys.</p>
                        <p>A <u title={"Rooms containing fixed devices or installations connected to the building. Access to this rooms is restricted to persons in charge of operating, surveillance or repair."}>technical room <i className="bi bi-info-circle"></i></u> or a group of technical rooms constitutes a compartment.</p>
                    </div>

                    <div className={"mb-3" + (q24D ? " d-none" : "")} id={"q24"}>
                        <label htmlFor="q24Q" className={"form-label"}>Is the building equipped with a boiler:</label>
                        <select id="q24Q" className={"form-select"} required={!q24D} value={q24} onChange={(evt) => {
                            setQ24(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"mb-3" + (q25D ? " d-none" : "")} id={"q25"}>
                        <label htmlFor="q25Q" className={"form-label"}>Is the total effective heat output higher than 30 kW:</label>
                        <select id="q25Q" className={"form-select"} required={!q25D} value={q25} onChange={(evt) => {
                            setQ25(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"mb-3" + (q26D ? " d-none" : "")} id={"q26"}>
                        <label htmlFor="q26Q" className={"form-label"}>Is the total effective heat output higher than 70 kW:</label>
                        <select id="q26Q" className={"form-select"} required={!q26D} value={q26} onChange={(evt) => {
                            setQ26(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q27D ? " d-none": "")} id={"q27"}>
                        <p>The boiler should be installed in a technical room.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q28D ? " d-none": "")} id={"q28"}>
                        <p>The boiler room should have walls EI 120 and a door EI1 60.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q29D ? " d-none": "")} id={"q29"}>
                        <p>Waste rooms should have inner walls EI 60. Access from such rooms to the inside shall be provided by a door EI1 30-C.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q30D ? " d-none": "")} id={"q30"}>
                        <p>The access roads should enable the simultaneous presence of three 15 t vehicles.</p>
                        <p>The parking area for the fire brigade should have at least following size:</p>
                        <ul>
                            <li>20 m x 5 m if the vehicles are parked one behind each other (8 m if dead end)</li>
                            <li>10 m x 10 m if the vehicles are parked next to each other.</li>
                        </ul>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q31D ? " d-none": "")} id={"q31"}>
                        <p>The fire load density, according to EN 1991-1-2 annex E, should be determined for each compartment.</p>
                    </div>

                    <div className={"mb-3" + (q32D ? " d-none" : "")} id={"q32"}>
                        <label htmlFor="q32Q" className={"form-label"}>Does the building have only one floor level:</label>
                        <select id="q32Q" className={"form-select"} required={!q32D} value={q32} onChange={(evt) => {
                            setQ32(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q33D ? " d-none": "")} id={"q33"}>
                        <p>The maximum allowed <u title={"the gross  horizontal area measured without any deduction between the inner surfaces of the walls surrounding the compartment. it is acceptable to deduct the area of openings in the floor."}>area of the compartment <i className="bi bi-info-circle"></i></u> is obtained by dividing 5700 GJ, or 34200 GJ if the compartment is sprinkler protected, by the fire load density.</p>
                    </div>

                    <div className={"mb-3" + (q34D ? " d-none" : "")} id={"q34"}>
                        <label htmlFor="q34Q" className={"form-label"}>Does the industrial building have more than one <u title={"The higher floor covers the entire surface of the building."}>storey <i className="bi bi-info-circle"></i></u>:</label>
                        <select id="q34Q" className={"form-select"} required={!q34D} value={q34} onChange={(evt) => {
                            setQ34(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q35D ? " d-none": "")} id={"q35"}>
                        <p>Each storey should be executed as a separated industrial compartment. </p>
                        <p>The maximum allowed area of the compartment should be reduced by multiplying with a reduction factor 0.25 if the building (low-rise or mid-rise) has multiple compartiments above the evacuation floor. In the other cases a reduction factor of 0.1 should be applied. </p>
                    </div>

                    <div className={"mb-3" + (q36D ? " d-none" : "")} id={"q36"}>
                        <label htmlFor="q36Q" className={"form-label"}>Does the industrial compartment have <u title={"An intermediate floor is a closed floor situated in a compartment that is not used exclusively for moving about on but on which goods and machinery may also be found. An intermediate floor is typically not covering more than 1/3 of the building area. Open floors are provided with regularly distributed openings covering at least 25 % of the surface. They are not considered to be intermediate floors. The number of intermediate floors in a compartment is the maximum number of intermediate floors, crossed by any vertical line."}>intermediate floors <i className="bi bi-info-circle"></i></u>:</label>
                        <select id="q36Q" className={"form-select"} required={!q36D} value={q36} onChange={(evt) => {
                            setQ36(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q37D ? " d-none": "")} id={"q37"}>
                        <p>The maximum allowed area should be multiplied by a reduction factor.  The reduction factor is 0.75 for one intermediate floor, 0.5 for two intermediate floors, 0.25 for three intermediate floors and 0.2 for more  than three intermediate floors. The new maximum area is including the intermediate floor.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q38D ? " d-none": "")} id={"q38"}>
                        <p>The minimum stability of the structural elements in the event of fire should be determined by calculation of the equivalent time of fire exposure, following EN 1991-1-2 annex F.</p>
                    </div>

                    <div className={"mb-3" + (q39D ? " d-none" : "")} id={"q39"}>
                        <label htmlFor="q39Q" className={"form-label"}>Is the fire load density, according to EN 1991-1-2 annex E,  in the compartment higher than 350 MJ/m²:</label>
                        <select id="q39Q" className={"form-select"} required={!q39D} value={q39} onChange={(evt) => {
                            setQ39(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    {q39 === "no" &&     <div className={"ms-5 border my-2 bg-light p-3 shadow-sm"}>
                        <p>Class A</p>
                    </div>}

                    <div className={"mb-3" + (q40D ? " d-none" : "")} id={"q40"}>
                        <label htmlFor="q40Q" className={"form-label"}>Is the fire load density in the compartment higher than 900 MJ/m²:</label>
                        <select id="q40Q" className={"form-select"} required={!q40D} value={q40} onChange={(evt) => {
                            setQ40(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    {q40 === "no" &&     <div className={"ms-5 border my-2 bg-light p-3 shadow-sm"}>
                        <p>Class B</p>
                    </div>}
                    {q40 === "yes" &&     <div className={"ms-5 border my-2 bg-light p-3 shadow-sm"}>
                        <p>Class C</p>
                    </div>}

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q41D ? " d-none": "")} id={"q41"}>
                        <p>The maximum allowed area of the compartment is 25,000 m² without sprinklers and 150,000 m² with sprinklers.</p>
                    </div>

                    <div className={"mb-3" + (q42D ? " d-none" : "")} id={"q42"}>
                        <label htmlFor="q42Q" className={"form-label"}>Does the building have a structure with stability to fire at least R 30 (e.g. reinforced concrete):</label>
                        <select id="q42Q" className={"form-select"} required={!q42D} value={q42} onChange={(evt) => {
                            setQ42(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q43D ? " d-none": "")} id={"q43"}>
                        <p>The maximum allowed area of the compartment is 5,000 m² without sprinklers and 40,000 m² with sprinklers.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q44D ? " d-none": "")} id={"q44"}>
                        <p>The maximum allowed area of the compartment is 10,000 m² without sprinklers and 60,000 m² with sprinklers.</p>
                    </div>

                    <div className={"mb-3" + (q45D ? " d-none" : "")} id={"q45"}>
                        <label htmlFor="q45Q" className={"form-label"}>Do al structural elements of the building have a structural stability with resistance to fire of at least R30 (e.g. reinforced concrete):</label>
                        <select id="q45Q" className={"form-select"} required={!q45D} value={q45} onChange={(evt) => {
                            setQ45(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"mb-3" + (q46D ? " d-none" : "")} id={"q46"}>
                        <label htmlFor="q46Q" className={"form-label"}>Is the compartment used for no other purpose than storage:</label>
                        <select id="q46Q" className={"form-select"} required={!q46D} value={q46} onChange={(evt) => {
                            setQ46(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>


                    {q46 === "yes" &&     <div className={"ms-5 border my-2 bg-light p-3 shadow-sm"}>
                        <p>Warehouse</p>
                    </div>}

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q47D ? " d-none": "")} id={"q47"}>
                        <p>The maximum allowed area of the compartment is 5,000 m² without sprinklers and 30,000 m² with sprinklers.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q48D ? " d-none": "")} id={"q48"}>
                        <p>The maximum allowed area of the compartment is 5,000 m² without sprinklers and 12,500 m² with sprinklers.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q49D ? " d-none": "")} id={"q49"}>
                        <p>The maximum allowed area of the compartment is 2,000 m² without sprinklers and 7,000 m² with sprinklers.</p>
                    </div>

                    <div className={"mb-3" + (q50D ? " d-none" : "")} id={"q50"}>
                        <label htmlFor="q50Q" className={"form-label"}>Is the fire load density, according to EN 1991-1-2 annex E,  in the compartment higher than 350 MJ/m²:</label>
                        <select id="q50Q" className={"form-select"} required={!q50D} value={q50} onChange={(evt) => {
                            setQ50(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    {/*{q46 === "no" &&     <div className={"ms-5 border my-2 bg-light p-3 shadow-sm"}>
                        <p>Class A</p>
                    </div>}*/}

                    <div className={"mb-3" + (q51D ? " d-none" : "")} id={"q51"}>
                        <label htmlFor="q51Q" className={"form-label"}>Is the fire load density in the compartment higher than 900 MJ/m²:</label>
                        <select id="q51Q" className={"form-select"} required={!q51D} value={q51} onChange={(evt) => {
                            setQ51(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q52D ? " d-none": "")} id={"q52"}>
                        <p>Compartments class A should be separated by fire walls EI 60. Structural elements supporting these walls should have R 60.</p>
                        <p>Door and shutters in the compartment walls should have EI1 60 and be self-closing or self-closing in case of fire.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q53D ? " d-none": "")} id={"q53"}>
                        <p>Compartments class B should be separated by fire walls EI 120. Structural elements supporting these walls should have R 120.</p>
                        <p>Door and shutters in the compartment walls should have EI1 60 and be self-closing or self-closing in case of fire.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q54D ? " d-none": "")} id={"q54"}>
                        <p>Compartments class C should be separated by fire walls EI 120. Structural elements supporting these walls should have R 120.</p>
                        <p>Door and shutters in the compartment walls should have EI1 60 and be self-closing or self-closing in case of fire.</p>
                    </div>

                    <div className={"mb-3" + (q55D ? " d-none" : "")} id={"q55"}>
                        <label htmlFor="q55Q" className={"form-label"}>Is the area of the compartment more than 10,000 m²:</label>
                        <select id="q55Q" className={"form-select"} required={!q55D} value={q55} onChange={(evt) => {
                            setQ55(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"mb-3" + (q80D ? " d-none" : "")} id={"q80"}>
                        <label htmlFor="q80Q" className={"form-label"}>Is the area of the compartment more than 2000 m²:</label>
                        <select id="q80Q" className={"form-select"} required={!q80D} value={q80} onChange={(evt) => {
                            setQ80(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"mb-3" + (q56D ? " d-none" : "")} id={"q56"}>
                        <label htmlFor="q56Q" className={"form-label"}>Is the area of the compartment more than 500 m²:</label>
                        <select id="q56Q" className={"form-select"} required={!q56D} value={q56} onChange={(evt) => {
                            setQ56(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"mb-3" + (q57D ? " d-none" : "")} id={"q57"}>
                        <label htmlFor="q57Q" className={"form-label"}>Is the compartment protected with ESFR sprinklers, watermist or a gaseous extinguishing system:</label>
                        <select id="q57Q" className={"form-select"} required={!q57D} value={q57} onChange={(evt) => {
                            setQ57(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q81D ? " d-none": "")} id={"q81"}>
                        <p>The compartment should be protected with a fire detection system with total surveillance of the compartment.</p>
                        <p>A manually operated detection system suffices.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q58D ? " d-none": "")} id={"q58"}>
                        <p>The compartment should be protected by a Smoke and Heat Control System complying to the Belgian Standard NBN S 21-208-1.</p>
                        <p>If the compartment is equipped with sprinklers, the installation shall be controlled automatically by the sprinkler alarm valve.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q59D ? " d-none": "")} id={"q59"}>
                        <p>The compartments should be protected with an automatic fire detection system with total suveillance of the compartment.</p>
                        <p>The concept of the installation should comply to the Belgian Standard NBN S 21-100-1.</p>
                        <p>All the installation parts should comply to the relevant European Standard EN 54.</p>
                        <p>A risk analysis will be provided to determine the specific needs.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q60D ? " d-none": "")} id={"q60"}>
                        <p>The site needs to have a central control and command post. </p>
                        <p>The various active fire safety installation can be monitored from this central control and command post.</p>
                        <p>The central control and command post is a room with inner walls EI 60. Doors in these walls have EI1 30.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q61D ? " d-none": "")} id={"q61"}>
                        <p>Distance between the industrial and any opposite building should be at least 16 m. </p>
                        <p>A lower distance can be accepted when one of the buildings has a façade EI 60.</p>
                        <p>The required distance is divided by 2 when the façade of the building is protected with sprinklers.</p>
                        <p>No minimum distance is required when both buildings are protected with sprinklers or another automatic extinguishing system.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q62D ? " d-none": "")} id={"q62"}>
                        <p>Each compartment should have at least two exits giving access to a <u title={"a place outside the building or, where applicable, that part of the building located outside the compartment with the fire and from which it is possible to leave the building without having to pass through that compartment."}>safe place <i className="bi bi-info-circle" /></u>. The exits are situated in opposite areas.</p>
                        <p>Exception (only one exit):</p>
                        <ul>
                            <li>in the case of rooms, compartments or storeys where a limited number of people is sporadically present for maintenance or inspection of the installations</li>
                            <li>when the number of users is less than 50 and the maximum travel distance to reach a safe place is less than 30 m.</li>
                        </ul>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q63D ? " d-none": "")} id={"q63"}>
                        <p>The distance to travel to reach an exit should be no more than 60 m in a compartment without sprinklers and 90 m with sprinklers.</p>
                        <p>The maximum shared part of the travel distance towards the exit is 30 m or 45 m when  the compartment is sprinklered.  </p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q64D ? " d-none": "")} id={"q64"}>
                        <p>The doors and evacuation routes giving access to outside or a safe place should have a <u title={"The serviceable width of an emergency exit area (stairs, landing, chamber, evacuation route, escape passage etc.) is the clear width free of any obstacle to a minimum height of 2 m."}>serviceable width <i className="bi bi-info-circle" /></u> of at least 0.8 m.</p>
                        <p>These should open in the direction of the evacuation flow.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q65D ? " d-none": "")} id={"q65"}>
                        <p>Each compartment shall be served by at least: </p>
                        <ul>
                            <li>one exit if the maximum number of occupants is less than 100;</li>
                            <li>two exits if the maximum number of occupants is equal or greater than 100 but less than 500;</li>
                            <li>2+n exits, where n is the next whole number above the ratio of the maximum number of occupants and 1000, if the number of occupants is more than 500.</li>
                        </ul>
                        <p>The exits are situated in opposite opposite areas.</p>
                    </div>

                    <div className={"mb-3" + (q66D ? " d-none" : "")} id={"q66"}>
                        <label htmlFor="q66Q" className={"form-label"}>Does the compartment have sleeping rooms:</label>
                        <select id="q66Q" className={"form-select"} required={!q66D} value={q66} onChange={(evt) => {
                            setQ66(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q67D ? " d-none": "")} id={"q67"}>
                        <p>No point of a compartment shall be at a distance greater than: </p>
                        <ul>
                            <li>30 m from the evacuation route leading to exits;</li>
                            <li>45 m from the access to the nearest exit;</li>
                            <li>80 m from the access to a second exit.</li>
                        </ul>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q68D ? " d-none": "")} id={"q68"}>
                        <p>The part of the building with sleeping rooms shall seperated from the rest of the building by walls EI 60 or EI 30 for a one storey building.</p>
                        <p>Doors in such walls shall have EI1 30.</p>
                        <p>No point of a compartment with sleeping rooms shall be at a distance greater than: </p>
                        <ul>
                            <li>20 m from the evacuation route leading to exits;</li>
                            <li>30 m from the access to the nearest exit;</li>
                            <li>60 m from the access to a second exit.</li>
                        </ul>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q69D ? " d-none": "")} id={"q69"}>
                        <p>The length of dead-end evacuation routes shall not exceed 15 m.</p>
                    </div>

                    <div className={"mb-3" + (q70D ? " d-none" : "")} id={"q70"}>
                        <label htmlFor="q70Q" className={"form-label"}>Does the building have a total floor area of more than 500 m²:</label>
                        <select id="q70Q" className={"form-select"} required={!q70D} value={q70} onChange={(evt) => {
                            setQ70(evt.target.value);
                        }}>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q71D ? " d-none": "")} id={"q71"}>
                        <p>The building needs to be equipped with fire hose reels. Each point of the building needs to be reached by the water jet.</p>
                        <p>Each fire hose reel will be equipped with a coupling type DSP 45.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q72D ? " d-none": "")} id={"q72"}>
                        <p>The industrial compartments should be equipped with appropriate extinguishing equipments. The nature and quantity of these shall be determined by the owner in consultation with the fire service.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q73D ? " d-none": "")} id={"q73"}>
                        <p>One or more safe and effective parking areas must be provided close to the industrial building, and this area (these areas) must be accessible at all times to the fire servides' vehicles.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q74D ? " d-none": "")} id={"q74"}>
                        <p>The access road to the industrial building should be equipped with hydrants with a minimum flow of 60 m³/h. The maximum travel distance between two fire hydrants is 100 m. A hydrant should be close (maximum 15 m) to the fire brigades parking area.</p>
                        <p>This primary supply of water may be supplemented, following consultation with the fire services, by a secondary and possibly third supply of water.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q75D ? " d-none": "")} id={"q75"}>
                        <p>The buildings should be equipped with alarm push buttons and sirens. </p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q76D ? " d-none": "")} id={"q76"}>
                        <p>The building needs to be equipped with fire extinguishers appropriate for the present risks.</p>
                        <p>The number of fire extinguishers is sufficient to have at least one <u title={"extinguisher containing : 6 kg powder type AB = 1 unit; 9 kg powder type AB = 1.5 units; 6 kg powder type ABC = 1 unit; 9 kg powder type ABC = 1.5 units; 6 l water + foam additive = 1 unit; 9 l water + foam additive = 1.5 units; 5 kg CO2 = 0.5 units"}>extinguishing unit <i className="bi bi-info-circle" /></u> per 150 m² floor area.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q77D ? " d-none": "")} id={"q77"}>
                        <p>The building should be equipped with emergency lighting complying to EN 1838.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q78D ? " d-none": "")} id={"q78"}>
                        <p>Exit and emergency exit signs must comply with the Royal Decree of 17 June 1997.</p>
                    </div>

                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (q79D ? " d-none": "")} id={"q79"}>
                        <p>This list of regulations is not complete.</p>
                        <p>Requirements concerning evacuation are not fully included.</p>
                        <p>Requirements concerning the access roads are not fully included.</p>
                        <p>Specific requirements for medium-rise and high-rise building are not included because they will, as far as we know, not be present on the site.</p>
                    </div>


                    <div className={"ms-5 border my-2 bg-light p-3 shadow-sm" + (RdD ? " d-none": "")}>
                        <p>The royal decree is not applicable.</p>
                    </div>

                    <button type={"submit"} className={"btn btn-success"}>Save</button>
                </Fragment>
            }
        </form>
    );
}

export default DesignGuide;