import React from "react";
import logo from "../img/logo.png";
import Logout from "./Logout";
import {NavLink} from "react-router-dom";

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={logo} alt="" height="60" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink to={'/designGuide'} className={"nav-link"} activeClassName={"active"}>Design Guide</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/forms'} onClick={(evt) => {
                                if (window.location.pathname === "/designGuide") {
                                    const sure = window.confirm("Your data is not saved yet. Are you sure you want to leave this page?");
                                    if (!sure) {
                                        evt.preventDefault();
                                    }
                                }
                            }} className={"nav-link"} activeClassName={"active"}>Form history</NavLink>
                        </li>
                    </ul>
                    <Logout />
                </div>
            </div>
        </nav>
    );
}

export default Navbar;