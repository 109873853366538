import React, {useState} from "react";
import Error from "./Error";
import {Link} from "react-router-dom";
import Success from "./Success";
import logo from "../img/login.jpg";
const endPoint = process.env.REACT_APP_endPoint || 'https://designguide.fesg.ldit.be/api/v1';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState("");

    async function onSubmit(evt) {
        evt.preventDefault();
        setErrors([]);

        try {
            const response = await fetch(endPoint + '/auth/generateLink', {
                method: "POST",
                body: JSON.stringify({
                    email
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const json = await response.json();

            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                setSuccess("An email has been sent to you with a link to reset your password.");
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end."]);
        }
    }

    return (
        <div className={"row w-50 mx-auto"} >
            <div className={"col mt-5 border-right text-center p-0"} style={{backgroundColor: "darkgrey"}}>
                <img src={logo} style={{width: '100%'}}/>
            </div>
            <div className={"col mt-5 border p-3 bg-white align-items-center d-flex"}>
                <div className={"flex-fill"}>
                    {errors.map((error, index) => <Error key={index} message={error} />)}
                    {success && <Success message={success}/>}
                    <form onSubmit={onSubmit}>
                        <div className="mb-3">
                            <label htmlFor="email" color={"form-label"}>Email address:</label>
                            <input type="email" className={"form-control"} id={"email"} value={email} required={true} onChange={(evt) => {
                                setEmail(evt.target.value);
                            }} placeholder={"Email address"}/>
                        </div>
                        <p><Link to={'/login'}>Sign in</Link></p>

                        <button type={"submit"} className={"btn btn-primary"}>Reset password</button>
                    </form>
                </div>

            </div>
        </div>
      /*  <div className={"mt-5 border p-3 bg-light w-50 mx-auto shadow"}>
            {errors.map((error, index) => <Error key={index} message={error} />)}
            {success && <Success message={success}/>}
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email address:</label>
                    <input type="email" className={"form-control"} id={"email"} value={email} required={true} onChange={(evt) => {
                        setEmail(evt.target.value);
                    }} placeholder={"Email address"}/>
                </div>
                <div className="text-center">
                    <button type={"submit"} className={"btn btn-primary"}>Reset password</button>
                </div>
                <p><Link to={'/login'}>Sign in</Link></p>
            </form>
        </div>*/
    )
}

export default ForgotPassword;