import React, {useState} from "react";
import logo from "../img/login.jpg";
import Error from "./Error";
import {useParams, useHistory, Link} from "react-router-dom";
import {resetPassword} from "../models/auth";

function ResetPassword() {
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const history = useHistory();

    const {token} = useParams();

    async function submit(evt) {
        evt.preventDefault();
        setErrors([]);

        try {
            const response = await resetPassword(token, password);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                history.push('/login');
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end."]);
        }
    }

    return (
        /*<div className={"row w-50 mx-auto"} style={{height: 'max-content'}}>
            <div className={"col mt-5 border-right text-center p-3"} style={{backgroundColor: "darkgrey"}}>
                <div style={{height: '100%'}}>
                    <img src={logo} style={{width: '80%'}}/>
                </div>
            </div>
            <div className={"col mt-5 border p-3 bg-white"}>
                {errors.map((error, index) => <Error key={index} message={error} />)}
                <form onSubmit={submit}>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" className={"form-control"} id={"password"} value={password} required={true} onChange={(evt) => {
                            setPassword(evt.target.value);
                        }} placeholder={"Password"}/>
                    </div>
                    <button type={"submit"} className={"btn btn-primary"}>Set password</button>
                </form>
            </div>
        </div>*/
        <div className={"row w-50 mx-auto"} >
            <div className={"col mt-5 border-right text-center p-0"} style={{backgroundColor: "darkgrey"}}>
                <img src={logo} style={{width: '100%'}}/>
            </div>
            <div className={"col mt-5 border p-3 bg-white align-items-center d-flex"}>
                <div className={"flex-fill"}>
                    {errors.map((error, index) => <Error key={index} message={error} />)}
                    <form onSubmit={submit}>
                        <div className="mb-3">
                            <label htmlFor="password" className={"form-label"}>Password</label>
                            <input type="password" className={"form-control"} id={"password"} value={password} required={true} onChange={(evt) => {
                                setPassword(evt.target.value);
                            }} placeholder={"Password"}/>
                        </div>
                        <p><Link to={'/login'}>Sign in</Link></p>

                        <button type={"submit"} className={"btn btn-primary"}>Set password</button>
                    </form>
                </div>

            </div>
        </div>
    );

}

export default ResetPassword;