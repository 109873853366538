const endPoint = process.env.REACT_APP_endPoint || 'https://designguide.fesg.ldit.be/api/v1';

export async function getForms(token) {
    const response = await fetch(endPoint + '/forms', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    return response.json();
}

export async function createForm(projectName, data, token) {
    const response = await fetch(endPoint + '/forms', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            projectName,
            data
        })
    });

    return response.json();
}

export async function updateForm(formId, projectName, data, token) {
    const response = await fetch(endPoint + '/forms/' + formId, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            projectName,
            data
        })
    });

    return response.json();
}

export async function getOne(formId, token) {
    const response = await fetch(endPoint + '/forms/' + formId, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    return response.json();
}